import { useEffect, useRef, useCallback } from "react";

/**
 * useDebounce hook
 *
 * @param fn - The function to debounce
 * @param delay - The debounce delay in milliseconds
 * @returns The debounced function
 */
function useDebounce(fn, delay) {
  const timeoutRef = useRef(undefined);

  const debouncedFn = useCallback(
    (...args) => {
      if (timeoutRef.current !== undefined) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = window.setTimeout(() => {
        fn(...args);
      }, delay);
    },
    [fn, delay]
  );

  useEffect(() => {
    return () => {
      if (timeoutRef.current !== undefined) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [debouncedFn]);

  return debouncedFn;
}

export default useDebounce;
